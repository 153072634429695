.color {
  &-white {
    color: var(--c-white);
  }

  &-black {
    color: var(--c-black);
  }

  &-inherit {
    color: inherit;
  }

  &-primary {
    color: var(--c-primary);
  }

  &-secondary {
    color: var(--c-secondary);
  }

  &-light {
    color: var(--c-light);
  }

  &-gray {
    color: var(--c-gray);
  }

  &-dark {
    color: var(--c-dark);
  }

  &-accent {
    color: var(--c-accent);
  }
}
