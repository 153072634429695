@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;
.services-hero {

  @include size('padding-top', 194, 50);
  @include size('padding-bottom', 194, 50);

  .inner {
    max-width: 800px;
  }
}