/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */

:root {
  --content-width: 1312px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-value: 1342;

  // TODO: формула для розрахунку колонок
  // --col-width: calc(100% / 12);
  // --col-gap: 24px;

  --header-indents: 29px;
  --main-icon-size: 40px;

  // fonts
  --font-family-primary: "DM Sans", sans-serif;
  --font-family-secondary: "DM Sans", sans-serif;

  // line-height
  --lineHeight: 1.4;
  --letterSpacing: 0;

  // font-weight
  --fw-400: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  // main-font-size
  --fz-text-normal: 16px;

  // colors
  --c-white:  #ffffff;
  --c-black:  #343434;

  --c-primary: #4f6a5a;
  --c-secondary: #343434;

  --c-light: #f7f7f7;
  --c-dark: #343434;
  --c-gray: #ededed;

  --c-accent: #4f6a5a;

  //gradients
  --main-gradient: linear-gradient(0.13deg, rgba(#ffc61b, 0.4) 0.11%, rgba(#fcf5df, 0.4) 111.96%);
  --dark-gradient: linear-gradient(270deg, rgba(0, 0, 0, 0.0001) 0%, #161C2D 99.54%);

}