@use "./../../../scss/utils/functions/"as*;
@use "./../../../scss/utils/mixins/"as*;

.terms-page .page-body {
  background-color: var(--c-primary);
  color: var(--c-white);
}

.policy {

  .container {
    max-width: 864px;
  }

  h1 {
    text-align: center;
    margin-bottom: 40px;
    font-weight: 700;
    @include size('font-size', 56, 30)
  }

  &__inner {}

  &__content {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 40px;

    h2 {
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 40px;
    }

    p {
      margin-bottom: 20px;
    }
  }

  a {
    text-decoration: underline;
  }
}