

.project-one-page {

  h1, h2 {
    font-weight: bold;
  }

  .content {
    max-width: 900px;
  }

  .image {
    border-radius: 24px;
  }

  p + h2 {
    margin-top: 40px;
  }
}