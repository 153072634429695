@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;
.about-us {

  .container {
    @include rmax($xl) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @include rmax($lg) {
      grid-template-columns: 1fr;
    }
  }

  .content {
    max-width: 360px;
    @include rmax($xl) {
      max-width: 100%;
    }
  }

  .image {
    @include rmax($lg) {
      width: 100%;
      height: 300px;
    }
  }

}