@use "../../../../scss/utils/functions/"as*;
@use "../../../../scss/utils/mixins/"as*;

$linkColor: var(--c-black);
$linkColorOnHover: var(--c-primary);
$linkFontSize: 16px;
$linkFontWeight: 500;
$gapBetweenLinks: 0;
$outlineColor: var(--c-primary);
$menuRight: auto;

$makeResponsiveBreakpoint: $lg;
$navResponsiveBg: var(--c-white);
$topPos: 75px;

.nav {

  @include rmax($sm) {
    justify-content: space-between;
  }
  &.opened {
    .nav__inner {
      transform: translateY(0);
      transition: transform 0.2s ease-in-out;
    }
  }

  &__inner {
    @include rmax($makeResponsiveBreakpoint) {
      padding: 45px 0 30px;
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      top: calc($topPos - 15px);
      flex-direction: column;
      background-color: $navResponsiveBg;
      transform: translateY(-200%);
      overflow-y: auto;
      z-index: -1;
    }

    @include rhmax(600) {
      height: calc(100svh - $topPos);
    }
  }



  .menu {
    @include rmax($makeResponsiveBreakpoint) {
      flex-direction: column;
      margin-bottom: 30px;
    }
    @include rhmax(600) {
      overflow-y: scroll;
    }
  }

  .actions {
    @include rmax($makeResponsiveBreakpoint) {
      flex-direction: column;
    }
  }

}

.logo {
  display: block;
  width: fit-content;
  outline: 2px solid transparent;
  transition: 0.3s ease-in-out;

  &:focus {
    outline-color: rgba(#000, 0.5);
    outline-offset: 10px;
  }

  @include rmax($xs) {
    max-width: 200px;
  }
}

.link {
  display: block;
  font-size: $linkFontSize;
  font-weight: $linkFontWeight;
  white-space: nowrap;
  text-transform: capitalize;
  color: $linkColor;
  background-color: transparent;
  border-radius: 50px;
  outline: 2px solid transparent;
  padding: 0 24px;
  align-self: center;
  transition: 0.3s ease-in-out;

  &:focus {
    outline-color: rgba(#000, 0.5);
    outline-offset: 10px;
  }

  @include hover {
    color: $linkColorOnHover;
    // text-decoration: underline 1px solid black;
  }

  &.current {
    color: $linkColorOnHover;
    font-weight: bold;
  }

  @include rmax($makeResponsiveBreakpoint) {
    font-size: 24px;
    padding: 10px 20px;
  }
}