@use "./../../scss/utils/functions/"as*;
@use "./../../scss/utils/mixins/"as*;

.main-top {
  padding-top: 104px;

  @include rmax($sm) {
    // padding-top: 62px;
  }
}

.hero {


  .inner {
    // @include size('padding-left', 106, 0);
    padding-left: 106px;
    @include rmax($lg) {
      padding-left: 50px;
    }
    @include rmax($sm) {
      padding-left: 0;
    }
  }
}
