.custom-select {
  position: relative;
}

.select-btn {
  min-width: 220px;
  background-color: white;
  padding: 16px;
  border-radius: 12px;
  line-height: 1.5;

  &-arrow {
    display: flex;
    justify-content: space-between;
    &::after {
      content: '';
      min-width: 24px;
      height: 24px;
      background-color: #000;
      mask-image: url("data:image/svg+xml,%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.2559 1.07757C15.5814 1.40301 15.5814 1.93065 15.2559 2.25609L8.58926 8.92275C8.26382 9.24819 7.73618 9.24819 7.41074 8.92275L0.744077 2.25609C0.418641 1.93065 0.418641 1.40301 0.744077 1.07757C1.06952 0.752137 1.59715 0.752137 1.92259 1.07757L8 7.15499L14.0774 1.07757C14.4028 0.752137 14.9305 0.752137 15.2559 1.07757Z' fill='%234B4B4B'/%3E%3C/svg%3E%0A");
      mask-repeat: no-repeat;
      mask-size: contain;
      mask-position: center;
    }
  }

  &.open {
    border-radius: 12px 12px 0 0;
    & ~ .select-list {
      display: block;
      z-index: 1;
    }
  }
}

.select-list {
  display: none;
  z-index: -100;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  right: 0;
  border-radius: 0 0 12px 12px;
  background-color: #fff;
  box-shadow: 0 15px 20px 1px rgba(#000, 0.15);
  overflow: hidden;
}

.select-item {
  padding: 8px 16px;
  font-weight: bold;
  line-height: 1.5;

  &.selected {
    background-color: var(--c-accent);
    color: #fff;
  }
}