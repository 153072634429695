@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;
@import "../base/global-vars";


.fz {
  &-lg {
    @include size('font-size', $fz-text-lg, 20);
  }

  &-md {
    @include size('font-size', $fz-text-md, 18);
  }

  &-xs {
    @include size('font-size', $fz-text-sm, $fz-text-sm);
  }
}