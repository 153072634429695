@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;
.projects-hero {

  @include size('padding-top', 193, 50);
  @include size('padding-bottom', 193, 50);

  .inner {
    max-width: 750px;
  }
}