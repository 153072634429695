@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;
.contact-us-page {

  .inner {
    max-width: 864px;

    a {
      font-size: 24px;
      line-height: 1.5;
      letter-spacing: 0.02em;
      display: block;
    }
  }

  h1 {
    font-weight: 700;
  }

  .form-wrap {
    h2 {
      font-weight: 700;
      max-width: 600px;
    }

    .form-group {
      width: 100%;
    }

    input, textarea {
      padding: 16px;
      border-radius: 8px;
      background-color: #f0f0f0;
      color: var(--c-primary);
      line-height: 1.5;
      width: 100%;

      outline: 2px solid transparent;
      outline-offset: 0;
      transition: 0.3s ease-in-out;

      &:focus {
        outline-color: var(--c-primary);
        outline-offset: 3px;
      }
    }

    textarea {
      resize: none;
      min-height: 131px;
    }

    .btn {
      width: 100%;
    }
  }
}

