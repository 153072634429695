@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;
.our-services {

  .card__image {
    @include rmax($lg) {
      height: 200px;
    }
  }

  .card__content {
    ul {
      padding-left: 30px;

      li {
        list-style-type: disc;
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }

    }
  }
}