@use "../../../../scss/utils/functions/"as*;
@use "../../../../scss/utils/mixins/"as*;

.footer {
  color: var(--color-white);
  background-color: #fff;
  @include size('padding-top', 74, 20);
  @include size('padding-bottom', 64, 20);

}
