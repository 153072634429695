/* stylelint-disable declaration-no-important */
@use "../../../../scss/utils/functions"as*;
@use "../../../../scss/utils/mixins"as*;

// settings

$backgroundColor: var(--c-primary);
$bgOnHover: transparent;

$textColor: var(--c-white);
$textOnHover: var(--c-primary);

$borderRadius: 8px;
$borderColor: transparent;
$borderColorOnHover: var(--c-primary);

$fontWeigth: var(--fw-500);
$paddingY: 12px;
$paddingX: 24px;

$fontSize: 16px;
$letterSpacing: 0;
$lineHeight: 1;

$borderWidth: 2px;

$outlineWidth: 2px;
$outlineColor: var(--c-accent);


*[class].btn {
  display: block;
  width: fit-content;
  border-radius: $borderRadius;
  padding: $paddingY $paddingX;
  font: inherit ;
  font-weight: $fontWeigth;
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
  white-space: nowrap;
  text-align: center;
  color: $textColor;
  border: $borderWidth solid $borderColor;
  background-color: $backgroundColor;
  outline: $outlineWidth solid transparent;
  // align-self: center;
  transition-property: background-color, color, border-color, outline;
  transition-duration: 0.2s;

  &:focus {
    outline-color: $outlineColor;
    outline-offset: 3px;
  }

  @include hover {
    border-color: $borderColorOnHover;
    color: $textOnHover !important ;
    background-color: $bgOnHover;
  }


  &-white {
    background-color: var(--c-white);
    color: var(--c-black);
    border-color:  transparent;
    @include hover {
      background-color: var(--c-accent);
      color: var(--c-white) !important;
    }

    &.active {
      background-color: var(--c-accent);
    }

  }

  &-arrow {
    display: flex;
    gap: 16px;
    align-items: center;

    &::after {
      content: '';
      display: block;
      min-width: 20px;
      height: 20px ;
      aspect-ratio: 1 / 1;
      background-color: currentColor;
      mask-image: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.07745 0.744078C1.40289 0.418641 1.93053 0.418641 2.25596 0.744078L8.92263 7.41074C9.24807 7.73618 9.24807 8.26382 8.92263 8.58926L2.25596 15.2559C1.93053 15.5814 1.40289 15.5814 1.07745 15.2559C0.752015 14.9305 0.752015 14.4028 1.07745 14.0774L7.15486 8L1.07745 1.92259C0.752015 1.59715 0.752015 1.06951 1.07745 0.744078Z' fill='white'/%3E%3C/svg%3E%0A");
      mask-repeat: no-repeat;
      mask-size: contain;
      mask-position: center;
      // transition: 0.2s ease-in-out;
    }

    @include hover {
      &::after {
        background-color: currentColor !important;
      }
    }

  }

}


button.formsapp-popup-button {
  color: $textColor;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  &.btn-white {
    color: var(--c-black) !important;
  }
}