
@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;
// ситуативні стилі та класи під проєкт

body {
  background-color: #E5E5E5;
}

.title,
.main-title,
.card__title {
  font-weight: var(--fw-700);
}

.py-100 {
  @include size('padding-top', 100, 30);
  @include size('padding-bottom', 100, 30);
}

.pt-100 {
  @include size('padding-top', 100, 30);
}

.pb-100 {
  @include size('padding-bottom', 100, 30);
}

.hero {

  .container {
    position: relative;
    z-index: 10;
  }
}

.image {
  object-fit: cover;
}